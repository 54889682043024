var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "video-box-content",
      staticStyle: { width: "100%", height: "100%" }
    },
    [
      _c(
        "div",
        {
          staticClass: "row",
          staticStyle: {
            display: "flex",
            "justify-content": "center",
            height: "100%"
          }
        },
        [
          _c(
            "div",
            {
              staticClass: "col-md-2",
              staticStyle: {
                height: "100%",
                "border-right": "1px solid #ddd",
                "overflow-y": "auto"
              },
              attrs: { id: "img-wrapper-div", left: "" }
            },
            [
              _c(
                "div",
                {
                  staticStyle: {
                    flex: "1",
                    "flex-direction": "column",
                    "padding-left": "10px"
                  }
                },
                _vm._l(_vm.imgWrapper, function(item, index) {
                  return _c(
                    "div",
                    {
                      key: index,
                      class: { active: index == _vm.activeImage },
                      staticStyle: { position: "relative", overflow: "hidden" },
                      attrs: {
                        id: "image" + index,
                        tabindex: index,
                        photo: ""
                      },
                      on: {
                        click: function($event) {
                          return _vm.selectPicture(index)
                        }
                      }
                    },
                    [
                      _c("img", { attrs: { src: item.imgData } }),
                      _vm._m(0, true)
                    ]
                  )
                }),
                0
              )
            ]
          ),
          _c("div", { staticClass: "col-md-10", attrs: { align: "center" } }, [
            _c(
              "div",
              {
                staticStyle: {
                  flex: "5 1 0%",
                  height: "100%",
                  "justify-content": "center"
                },
                attrs: { align: "center", right: "" }
              },
              [
                _c("video", {
                  attrs: {
                    width: "100%",
                    height: "98%",
                    autoplay: "",
                    controls: "",
                    src: this.playVideoUrl
                  }
                })
              ]
            )
          ])
        ]
      )
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("div", {
        staticStyle: {
          position: "absolute",
          width: "100%",
          height: "100%",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)"
        }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }