<template>
	<div class="video-box-content" style="width: 100%; height: 100%">
		<div class="row" style="display: flex; justify-content: center; height: 100%">
			<div id="img-wrapper-div" class="col-md-2" style="height: 100%; border-right: 1px solid #ddd; overflow-y: auto" left>
				<div style="flex: 1; flex-direction: column; padding-left: 10px">
					<div
						style="position: relative; overflow: hidden"
						v-for="(item, index) in imgWrapper"
						:key="index"
						:id="'image' + index"
						:class="{ active: index == activeImage }"
						@click="selectPicture(index)"
						:tabindex="index"
						photo
					>
						<!-- @keydown.up="priviousImage()"
						@keydown.left="priviousImage()"
						@keydown.down="nextImage()"
						@keydown.right="nextImage()" -->
						<img :src="item.imgData" />
						<div>
							<div style="position: absolute; width: 100%; height: 100%; top: 50%; left: 50%; transform: translate(-50%, -50%)"></div>
						</div>
					</div>
				</div>
			</div>
			<div align="center" class="col-md-10">
				<div align="center" right style="flex: 5 1 0%; height: 100%; justify-content: center">
					<video width="100%" height="98%" autoplay controls :src="this.playVideoUrl"></video>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import apiIndex from '../api/index';
let axiosExtention;
const baseUrl = apiIndex.baseUrl;
const targetUrl = { danger: apiIndex.dangerOccur, 'warn-work': apiIndex.warnWorkOccur };

export default {
	props: {
		detailsItem: { type: Object, required: true },
		urlFlag: { type: String, required: true },
		imgWrapper: { type: Array, required: true },
	},
	data: () => ({
		imageSrc: '/images/noimage.png',
		playVideoUrl: '',
		activeImage: 0,
	}),
	mounted() {
		axiosExtention = this.$jarvisExtention.axiosExtention;
		let selectedImage = document.getElementById('image0');
		selectedImage.focus();
		this.selectPicture(0);
	},
	methods: {
		//모달창에서 키 다운으로 이전 이미지로 이동
		// priviousImage() {
		// 	if (this.activeImage > 0) {
		// 		this.activeImage = this.activeImage - 1;
		// 		this.selectPicture(this.activeImage);
		// 	}
		// },
		// //모달창에서 키 다운으로 다음 이미지로 이동
		// nextImage() {
		// 	if (this.activeImage < this.imgWrapper.length - 1) {
		// 		this.activeImage = this.activeImage + 1;
		// 		this.selectPicture(this.activeImage);
		// 	}
		// },
		async setPlayVideoUrl(index) {
			let item = this.imgWrapper[index];
			let endFrameImgSeq = this.detailsItem.endFrameImgSeq == undefined ? null : this.detailsItem.endFrameImgSeq;
			await this.$axios
				.post(targetUrl[this.urlFlag].sendMakeVideo, {
					siteId: item.siteId,
					cctvId: item.cctvId,
					imgSaveDtm: item.imgSaveDtm,
					imgSaveFileNm: item.imgSaveFileNm,
					endFrameImgSeq: endFrameImgSeq, // for dangerOccurUrl
					strtFrameImgSeq: item.frameImgSeq, // for warnWorkOccurUrl
				})
				.then(
					function (r) {
						this.playVideoUrl = baseUrl + '/v2/api/video/' + this.urlFlag + '/' + r.data;
					}.bind(this),
				)
				.catch(axiosExtention.buildErrorHandler());
		},
		getBoxMargin(computedStyle, topOrBottom) {
			let margin = parseInt(computedStyle[topOrBottom], 10);
			return isNaN(margin) ? 0 : margin;
		},
		//스크롤링할 거리를 계산
		getDistanceForScrolling(index) {
			let distance = 0;
			let imgBox = document.getElementById('image0');
			let imgBoxStyle = window.getComputedStyle(imgBox);
			let imgBoxMargin = this.getBoxMargin(imgBoxStyle, 'marginTop') + this.getBoxMargin(imgBoxStyle, 'marginBottom');
			let imageBoxHeight = imgBox.clientHeight + imgBoxMargin;
			let oneWrapperBoxHeight = document.getElementById('img-wrapper-div').clientHeight;
			let numberOfBoxPerOneWrapperBox = oneWrapperBoxHeight / imageBoxHeight;
			if (index > numberOfBoxPerOneWrapperBox / 2 - 1) {
				distance = imageBoxHeight * index - imageBoxHeight * (numberOfBoxPerOneWrapperBox / 2) + imgBoxMargin * 2;
			}
			return distance;
		},
		//모달창에서 이미지를 선택했을 때
		selectPicture(index) {
			if (index < 0 || index >= this.imgWrapper.length) {
				return;
			}
			this.activeImage = index;
			//스크롤바를 내리는 용도
			$('.video-box-content [left]').animate({ scrollTop: this.getDistanceForScrolling(index) }, 50);
			//
			this.setPlayVideoUrl(index);
		},
	},
};
</script>

<style>
*:focus {
	outline: none;
}
.video-box-content img {
	width: 100%;
	height: 100%;
	object-fit: contain;
}

.video-box-content [photo] {
	cursor: pointer;
	border-radius: 4px;
	overflow: hidden;
}
.video-box-content [photo] img {
	max-height: 120px;
}
.video-box-content [photo].active {
	border: 5px solid #1878f3;
	box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 5px;
}

.video-box-content [left] [photo] {
	margin-top: 20px;
}
</style>
